<template>
	<v-card width="600" class="mx-auto mt-5">
		<v-card-title>
			<h1 class="display-1">
				{{ $t('auth.registerYourCompany') }}
			</h1>
		</v-card-title>
		<v-card-text>
			<v-form ref="form" v-model="valid">
				<v-text-field
					v-model="name"
					:label="$t('auth.name')"
					:rules="rules.name"
					required
					prepend-inner-icon="mdi-domain"
					rounded
					hide-details="auto"
					class="pb-3"
				/>
				<v-textarea
					v-model="description"
					:label="$t('courses.description')"
					:counter="descriptionMaxLength"
					:rules="rules.description"
					auto-grow
					outlined
					rounded
					hide-details="auto"
					class="pb-3"
				/>
				<v-text-field
					v-model="website"
					:label="$t('companies.website')"
					:rules="rules.website"
					prepend-inner-icon="mdi-earth"
					outlined
					rounded
					hide-details="auto"
					class="pb-3"
				/>
				<v-select
					v-model="employees"
					:label="$t('companies.employees')"
					:items="availableEmployeeOptions"
					item-text="name"
					item-value="key"
					:rules="rules.employees"
					return-object
					prepend-icon="mdi-account-group"
					outlined
					rounded
					hide-details="auto"
					class="pb-3"
				/>
				<v-autocomplete
					v-model="sectors"
					:items="selectableSectors"
					:loading="loading || loadingParent"
					:search-input.sync="search"
					:filter="customFilter"
					hide-details="auto"
					hide-selected
					item-text="name"
					item-value="id"
					:label="$t('companies.sectors')"
					:placeholder="`${$t('search.search')}...`"
					return-object
					chips
					multiple
					outlined
					rounded
					class="pb-3"
				>
					<template #no-data>
						<v-list-item v-if="search == null">
							<v-list-item-title>
								<strong>{{ $t('search.search') }}</strong>
							</v-list-item-title>
						</v-list-item>
						<v-list-item v-else>
							<v-list-item-title>
								{{ $t('search.noResults') }}
							</v-list-item-title>
						</v-list-item>
					</template>
				</v-autocomplete>
				<v-text-field
					v-model="coupon"
					:label="$t('companies.coupon')"
					:rules="rules.coupon"
					:hint="$t('companies.redeemCouponHelp2')"
					hide-details="auto"
					outlined
					rounded
					class="pb-3"
				/>
			</v-form>

			<v-form ref="formLogo" v-model="validLogo">
				<v-file-input v-model="logo" :label="$t('companies.logo')" @change="onLogoUpload" />
			</v-form>
			<div v-if="logoCompressed && logoUrl" class="text-center">
				<h3>{{ $t('explorer.readyToUpload') }}</h3>
				<p>{{ logo.name }}</p>
				<v-avatar size="160">
					<v-img :src="logoUrl" />
				</v-avatar>
			</div>
		</v-card-text>
		<v-divider />

		<v-card-actions>
			<v-btn color="primary" :loading="loading" @click="callRegister()">
				{{ $t('auth.registerYourCompany') }}
			</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Compressor from 'compressorjs'

// generic accent removal from input string, add any missing characters
function removeAccents(input) {
	if (!input) return ''
	const letters1 = 'äáàâăëéèêĕíìüúùûŭöóòôŏÄÁÀÂĂËÉÈÊĔÜÚÙÛŬÖÓÒÔŎßșȘ'
	const letters2 = 'aaaaaeeeeeiiuuuuuoooooAAAAAEEEEEUUUUUOOOOOssS'
	const patternLetters = new RegExp('[' + letters1 + ']', 'g')
	const lookupLetters = {}
	letters1.split('').forEach(function(letter, i) {
		lookupLetters[letter] = letters2[i]
	})
	const letterTranslator = function(match) {
		return lookupLetters[match] || match
	}
	return input.replace(patternLetters, letterTranslator)
}

export default {
	name: 'RegisterForm',
	data() {
		return {
			loading: false,
			valid: false,
			validLogo: false,
			name: '',
			description: '',
			website: '',
			employees: null,
			sectors: [],
			coupon: '',
			logo: null,
			logoCompressed: null,
			logoUrl: null,
			rules: {
				name: [
					(v) => !!v || this.$t('rules.required'),
					(v) => (v && v.length <= this.nameMaxLength) || this.$t('rules.length', { length: this.nameMaxLength })
				],
				description: [
					(v) => !!v || this.$t('rules.required'),
					(v) => (v && v.length <= this.descriptionMaxLength) || this.$t('rules.length', { length: this.descriptionMaxLength })
				],
				website: [
					(v) => {
						if (v) return (0 <= v.length && (v.startsWith('http://') || v.startsWith('https://'))) || this.$t('rules.website')
						else return true
					},
					(v) => 0 < v.length <= this.websiteMaxLength || this.$t('rules.length', { length: this.websiteMaxLength })
				],
				employees: [
					(v) => !!v || this.$t('rules.required'),
					(v) => (v && this.availableEmployeeOptions.map(({ key }) => key).includes(v.key)) || this.$t('rules.notSupported')
				],
				sectors: [
					(v) => !!v.length || this.$t('rules.required'),
					(v) => (v && this.allItemsInArray(v, this.availableSectors)) || this.$t('rules.notSupportedAtleast')
				],
				coupon: [(v) => 0 < v.length <= this.couponMaxLength || this.$t('rules.length', { length: this.couponMaxLength })]
			}
		}
	},
	computed: {
		nameMaxLength() {
			return 255
		},
		descriptionMaxLength() {
			return 1024
		},
		websiteMaxLength() {
			return 64
		},
		couponMaxLength() {
			return 64
		},
		selectableSectors() {
			return this.availableSectors.filter((sector) => !this.sectors.map((s) => s.id).includes(sector.id))
		},
		...mapGetters({
			availableEmployeeOptions: 'data/availableEmployeeOptions',
			availableSectors: 'companies/availableSectors'
		})
	},
	watch: {
		search(val) {
			if (val) this.queryAvailableSectors(val)
		}
	},
	created() {
		this.queryAvailableSectors('')
		this.fetchAvailableEmployeeOptions()
	},
	methods: {
		allItemsInArray(items, array) {
			for (let index = 0; index < items.length; index++) {
				if (!array.map((option) => option.key).includes(items[index].key)) return false
			}
			return true
		},
		queryAvailableSectors(search) {
			this.loading = true
			this.fetchAvailableSectors({ keywords: search }).then(() => {
				this.loading = false
			})
		},
		customFilter(item, queryText) {
			const text = removeAccents(item.name.toLowerCase())
			const searchText = removeAccents(queryText.toLowerCase())
			return text.includes(searchText)
		},
		onLogoUpload() {
			new Promise((resolve, reject) => {
				new Compressor(this.logo, {
					quality: 0.9,
					success: resolve,
					error: reject
				})
			})
				.then((result) => {
					this.logoCompressed = result
					this.logoUrl = URL.createObjectURL(this.logoCompressed)
				})
				.catch((error) => {
					console.log(error)
				})
		},
		callUpdateLogo() {
			this.$refs.form.validate()
			this.$refs.formLogo.validate()
			if (!this.valid || !this.validLogo) return
			this.loading = true
			const formData = new FormData()
			formData.append('logo', this.logoCompressed, this.logoCompressed.name)
			return this.updateCompanyLogo({ formData }).then(() => {
				this.logo = null
				this.logoCompressed = null
				this.logoUrl = null
			})
		},
		callRegister() {
			this.$refs.form.validate()
			if (!this.valid) return
			this.loading = true
			this.register({
				name: this.name,
				description: this.description,
				website: this.website,
				employees: this.employees,
				sectors: this.sectors,
				coupon: this.coupon
			})
				.then(({ success }) => {
					if (success) {
						if (this.logoCompressed) {
							this.callUpdateLogo().then(() => {
								this.$router.push({ name: 'Company' })
							})
						} else {
							this.$router.push({ name: 'Company' })
						}
					} else {
						this.loading = false
					}
				})
				.then(() => {
					this.loading = false
				})
		},
		...mapActions('companies', ['register', 'updateCompanyLogo', 'fetchAvailableSectors']),
		...mapActions('data', ['fetchAvailableEmployeeOptions'])
	}
}
</script>
